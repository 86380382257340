<template>
  <aside id="search-2" class="widget widget_search" @submit.prevent="getsearch">
    <form role="search" method="get" id="searchform" class="searchform" >
        <div>
          <input type="text" value="" name="s" ref="sinput" id="s" />
           <button  class="sc-button" type="submit" id="searchsubmit" value="Поиск">Поиск</button>
        </div>
      </form>
    </aside>

</template>

<script>
export default {
name : 'searchbar',
methods : {

getsearch(e)
{
console.log(this.$refs.sinput.value)

const search = this.$refs.sinput.value
this.$refs.sinput.value = ''

this.$router.push({name : 'rubricatorsearch', params : {search , type : 'search'}})

},
mkaction()
{

}

}


}
</script>
