<template>
  <aside id="calendar-2" class="widget widget_calendar"><div id="calendar_wrap" class="calendar_wrap"><table id="wp-calendar">
  <caption>{{curmonth}} {{curyear}}</caption>
  <thead>
  <tr>
    <th scope="col" title="Понедельник">Пн</th>
    <th scope="col" title="Вторник">Вт</th>
    <th scope="col" title="Среда">Ср</th>
    <th scope="col" title="Четверг">Чт</th>
    <th scope="col" title="Пятница">Пт</th>
    <th scope="col" title="Суббота">Сб</th>
    <th scope="col" title="Воскресенье">Вс</th>
  </tr>
  </thead>

  <tfoot>
  <tr>
    <td colspan="3" id="prev" @click="prevousclick" ><router-link    v-if="prevvisible" :to="{ name : 'rubricatorcalendar1',  params : {type : 'calendar', year : previousyear, month : previousmonthD}}">&laquo; {{previousmonth}}</router-link></td>
    <td class="pad">&nbsp;</td>
        <td colspan="3" id="next" class="pad" @click="nextclick" ><router-link  v-if="nextvisible" :to="{name : 'rubricatorcalendar1', params : {type : 'calendar', year :  nextyear, month : nextmonthD}}">&raquo; {{nextmonth}}</router-link></td>
  </tr>
  </tfoot>

  <tbody>
  <tr>
     <td> <span ref="c11" v-html="Calendar[1][1]"></span> </td>
      <td> <span ref="c12" v-html="Calendar[1][2]"></span> </td>
       <td> <span ref="c13" v-html="Calendar[1][3]"></span> </td>
        <td> <span ref="c14" v-html="Calendar[1][4]"></span> </td>
        <td><span ref="c15" v-html="Calendar[1][5]"></span> </td>
        <td><span ref="c16" v-html="Calendar[1][6]"></span> </td>
        <td> <span ref="c17"  v-html="Calendar[1][7]"></span></td>
  </tr>
  <tr>
    <td> <span ref="c21" v-html="Calendar[2][1]"></span> </td>
     <td> <span ref="c22" v-html="Calendar[2][2]"></span> </td>
      <td> <span ref="c23" v-html="Calendar[2][3]"></span> </td>
       <td> <span ref="c24" v-html="Calendar[2][4]"></span> </td>
       <td><span ref="c25" v-html="Calendar[2][5]"></span> </td>
       <td><span ref="c26" v-html="Calendar[2][6]"></span> </td>
       <td> <span ref="c27" v-html="Calendar[2][7]"></span></td>
  </tr>
  <tr>
    <td> <span ref="c31"  v-html="Calendar[3][1]"></span> </td>
     <td> <span ref="c32" v-html="Calendar[3][2]"></span> </td>
      <td> <span ref="c33" v-html="Calendar[3][3]"></span> </td>
       <td> <span ref="c34"  v-html="Calendar[3][4]"></span> </td>
       <td><span ref="c35" v-html="Calendar[3][5]"></span> </td>
       <td><span ref="c36" v-html="Calendar[3][6]"></span> </td>
       <td> <span ref="c37" v-html="Calendar[3][7]"></span></td>

  </tr>
  <tr>
    <td> <span ref="c41" v-html="Calendar[4][1]"></span> </td>
     <td> <span ref="c42" v-html="Calendar[4][2]"></span> </td>
      <td> <span ref="c43" v-html="Calendar[4][3]"></span> </td>
       <td> <span ref="c44" v-html="Calendar[4][4]"></span> </td>
       <td><span ref="c45" v-html="Calendar[4][5]"></span> </td>
       <td><span ref="c46"  v-html="Calendar[4][6]"></span> </td>
       <td> <span ref="c47" v-html="Calendar[4][7]"></span></td>
  </tr>
  <tr>
    <td> <span ref="c51"  v-html="Calendar[5][1]"></span> </td>
     <td> <span ref="c52" v-html="Calendar[5][2]"></span> </td>
      <td> <span ref="c53" v-html="Calendar[5][3]"></span> </td>
       <td> <span ref="c54" v-html="Calendar[5][4]"></span> </td>
       <td ref="c55"><span   v-html="Calendar[5][5]"></span> </td>
       <td><span ref="c56" v-html="Calendar[5][6]"></span> </td>
       <td> <span ref="c57" v-html="Calendar[5][7]"></span></td>
    <td class="pad" colspan="1">&nbsp;</td>
  </tr>
  </tbody>
  </table></div></aside>

</template>
<script>
import {getprevcalendar, getnextcalendar, getcurmonthposts} from "@/lib/utils.js"
export default {
computed : {


},
name : 'calendar',
props : ['year', 'month'],
data() {

const months  = [
               'Январь',
                'Февраль',
                'Март',
                "Апрель",
                "Май",
                "Июнь",
                "Июль",
                "Август",
                "Сентябрь",
                "Октябрь",
                "Ноябрь",
                "Декабрь",
              ]
const monthsShort = {
        '01':  'Янв',
        '02':  'Фев',
        '03':  'Мар',
        '04':  'Апр',
        '05':  'Май',
        '06':  'Июн',
        '07':  'Июл',
        '08':  'Авг',
        '09':  'Сен',
        '10':  'Окт',
        '11':  'Ноя',
        '12':  'Дек'
          }
let curmonth = ''
let curyear = ''
let prevvisible = false
let nextvisible = false
let previousmonth = ''
let previousmonthD = ''
let nextmonth = ''
let nextmonthD = ''
let previousyear = ''
let nextyear = ''
let curmonthD = ''
const actualdays = []
curyear = new Date().getFullYear()
const m = new Date().getMonth()
curmonth = months[m]
curmonthD = m+1

if(this.year)
  {
  //  console.log(`set curyear to ${this.year}`)
   curyear = this.year

  }

  if(this.month)
    {
      const m = this.month -1
      curmonth = months[m]
      curmonthD = m+1
    //  console.log(`curmonth = ${curmonth} curmonthD = ${curmonthD}`)
        }


const shday = {}
let i=1
while (i < 32) {
  shday[i] = i
  i = i+1
}

//console.log(shday)
//console.log(`calendar.vue data in work`)


return {
  prevvisible ,
  nextvisible ,
  previousmonth,
  previousmonthD,
  nextmonth,
  nextmonthD,
  previousyear,
  nextyear,
  actualdays,
months,
monthsShort ,
curmonth,
curyear,
curmonthD,
shday,
weeks : {},
Calendar : {
1: {},
2: {},
3 : {},
4: {},
5: {}
},
CalendarM : {}

  }

},
methods : {
prevousclick() {
//console.log(`previous click`)
//this.showday()
const pm = parseInt(this.previousmonthD)-1

this.curyear = this.previousyear
this.curmonth = this.months[pm]
this.curmonthD = this.previousmonthD

this.showday()
this.prevc()
this.nextc()
this.curmonthposts()

},
nextclick() {

  //console.log(`next click`)
  //this.showday()
  const pm = parseInt(this.nextmonthD)-1

  this.curyear = this.nextyear
  this.curmonth = this.months[pm]
  this.curmonthD = this.nextmonthD


  this.showday()
  this.prevc()
  this.nextc()
  this.curmonthposts()
},
prevc : async function() {

   const res = await getprevcalendar(this.curyear,this.curmonthD)

  // console.log('prevc res from axios is')
//  console.log(res)
   if(!res.status)
      {
        this.prevvisible = false
        return
      }
this.prevvisible = true
const pm = res.month
const py = res.year

this.previousmonth = this.monthsShort[pm]
this.previousmonthD = pm
this.previousyear = py
//console.log(this.previousmonth)
},
nextc : async function() {

    const res = await getnextcalendar(this.curyear,this.curmonthD)
    if(!res.status)
       {
         this.nextvisible = false
         return
       }

//console.log('nectc res from axios : ')
//console.log(res)

this.nextvisible = true
const pm = res.month
const py = res.year

this.nextmonth = this.monthsShort[pm]
this.nextmonthD = pm
this.nextyear = py

  },
curmonthposts : async function(){

const days = await getcurmonthposts(this.curyear,this.curmonthD)

if(!days.status)
  return

this.actualdays = days.dates

//console.log('this.actualdays')
//console.log(this.actualdays)

this.actualdays.forEach((day, i) => {
const d = parseInt(day)

const wmm = this.CalendarM[d]
//console.log('wmm')
//console.log(wmm)
const wom = wmm[0]
const dow = wmm[1]

let mm = parseInt(this.curmonthD)
if(mm < 10)
mm = `0${mm}`

let dd = parseInt(d)
if(d < 10)
dd = `0${d}`

//this.Calendar[wom][dow] = `<a  href="/rubricator/calendar/${this.curyear}/${mm}/${dd}" aria-label="Записи, опубликованные ${day}">${d}</a>`
this.Calendar[wom][dow] = `<a href="javascript:void(0);" role="button"  aria-label="Записи, опубликованные ${day}">${d}</a>`
const refname = `c${wom}${dow}`

const curyear1 = this.curyear
const self = this
this.$refs[refname].addEventListener('click',function(e) {

//console.log(self.$router)

self.$router.push({ replace: true, name: 'rubricatorcalendar2', params: { type: 'calendar1', year : curyear1, month : mm, day : dd  }, shallow: false  }).catch(() => {
  self.$router.go(self.$router.currentRoute)
})

},false)

});

//console.log('curmonthposts : ')
//console.log(this.Calendar)

},
showday()
{

  Date.prototype.getWeekOfMonth = function() {
  var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay() - 1;
  if (firstWeekday < 0) firstWeekday = 6;
  var offsetDate = this.getDate() + firstWeekday - 1;
  return Math.floor(offsetDate / 7);
    }

const calendar = {}

const dt1 = new Date(`${this.curyear}-${this.curmonthD}-01`)
const last = new Date(this.curyear, dt1.getMonth()+1, 0).toString("dd")
//console.log(`Last day is ${last}`)


Object.keys(this.shday).forEach((day, i) => {

if( parseInt(day) > parseInt(last))
{
  //console.log(`day ${day}  last ${last}  return`)
  return
    }
let dayofweek = new Date(`${this.curyear}-${this.curmonthD}-${day}`).getDay()
if(dayofweek == 0)
   dayofweek = 7

const WeekOfMonth = new Date(`${this.curyear}-${this.curmonthD}-${day}`).getWeekOfMonth()+1

if(!calendar[WeekOfMonth])
   calendar[WeekOfMonth] = {}

 calendar[WeekOfMonth][dayofweek] = day
this.CalendarM[day] = [WeekOfMonth,dayofweek]

//console.log(`day : ${day} dayofweek : ${dayofweek} weekofmonth : ${WeekOfMonth}`)


});


this.Calendar = calendar
//console.log("Calendar : ")
//console.log(this.Calendar)



},
generatecal : function() {

let wm = 1;
let wd = 1;

while (wm <= 5) {

this.weeks[wm]=''
//console.log(this.weeks[wm])

//console.log(this.Calendar[1])


    while (wd <= 7) {
if(!this.Calendar[wm][wd])
   {
    this.weeks[wm] = `${this.weeks[wm]}<td></td>`
     wd++
     continue
     }

this.weeks[wm] = `${this.weeks[wm]}<td>${this.Calendar[wm][wd]}</td>`
      wd++
    }
  wd=1

  wm++
}

//console.log('weeks:')
//console.log(this.weeks)

}

},
mounted() {
//console.log(`calendar.vue : mounted`)


this.showday()
//this.generatecal()
this.prevc()
this.nextc()

this.curmonthposts()

},
watch : {

month(newv,old){
  // console.log("change month to "+newv)


},
year(newv,old) {
  // console.log("change year to "+newv)
  
}


}



}
</script>
