<template>
  <span class="tag-links">
           <span v-for="(cat,index) in categories">
          <router-link :to="`/rubricator/${cat.id}`" :title="`Посмотреть все посты в ${cat.name}`">{{cat.name}}</router-link>
          <span v-if="index < categories.length-1" class="sep">|</span>
        </span>

      </span>
</template>

<script>
export default {
name : "taglinks",
props : ['categories'],
mounted() {
console.log(this.categories)

}

}

</script>
