<template>
  <div>
    <aside id="categories-2" class="widget widget_categories"><h4 class="widget-title">Рубрики</h4>		<ul>
  	<li :class="styles.anonce"><router-link to="/news/anonce" title="Анонсы">Анонсы</router-link>
  </li>
  	<li :class="styles.norubric"><router-link to="/rubricator/1" title="Без рубрики">Без рубрики</router-link>
  </li>
  	<li :class="styles.video"><router-link to="/media/video" title="Видео">Видео</router-link>
  </li>
  	<li :class="styles.media"><router-link to="/media" title="Медиа">Медиа</router-link>
  </li>
  	<li :class="styles.events"><router-link to="/news/events" title="Наши мероприятия">Наши мероприятия</router-link>
  </li>
  	<li :class="styles.foto"><router-link to="/media/foto" title="Фото">Фото</router-link>
  </li>
  	<li :class="styles.lectors_school"><router-link to="/projects/shkolalektorov/articles" title="Школа лекторов">Школа лекторов</router-link>
  </li>
  		</ul>
  </aside>
</div>
</template>
<script>
export default {
name : 'rubricator',
props : [ 'position' ],
data(){
let position = ''

    if(this.position)
      {
    position = this.position

        }



   return {
    styles : this.setposition(position)

    }
  },
  methods : {

setposition(position){
  const styles = {
   anonce : "cat-item cat-item-45",
   norubric : "cat-item cat-item-1",
   video : "cat-item cat-item-101",
   events : "cat-item cat-item-31",
   foto : "cat-item cat-item-36",
   lectors_school : "cat-item cat-item-129"
  }

if(styles[position])
    styles[position] =    styles[position] + ' current-cat'

  return styles
}


  },
  watch : {

position(n,o)
{
this.styles = this.setposition(n)

}

  }

}
</script>
