<template>
<div>

  <div class="col-lg-9 col-md-9 col-sm-12">
  <div class="blog blog-style-default">
<article v-for="news in newsitems" :id="`post-${news.id}`" :class="`rounded-corners default-background post-${news.id} post type-post status-publish format-standard has-post-thumbnail hentry category-announcements category-events tag-115 tag-128 tag-114 tag-106`">
  <div class="item-post-container">
<div class="item-post clearfix">


  <div class="post-image">

                    <router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" class="default"><img width="540" height="390" :src="news.imagesrcmedium" class="img-responsive wp-post-image" alt="Цикл лекций &quot;Внеурочные мероприятия&quot;" /></router-link>


  </div>
  <div class="post-text">
    <header class="entry-header">

      <div class="entry-title"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" rel="bookmark"><div v-html="`${news.title.rendered}`"> </div> </router-link></div>
      <div class="entry-meta">
                        <taglinks :categories="news.categoriesarray"/>
                    </div>
      <!-- .entry-meta -->
    </header>
    <!-- .entry-header -->

                <div class="entry-content">
                            <div v-html="news.excerpt.rendered"></div>
                </div>

    <div class="clearfix entry-info">
                    <span class="entry-date">{{news.postdateformated}}</span>																			<span class="read-more-link"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"> Далее</router-link></span>
                </div>
  </div>
</div>
</div>
</article><!-- #post-12118 -->


</div>
<Paginator :curpage="showpage" :totalpages="pagestotal" />
</div>

</div>
</template>

<script>
import axios from "axios"
import "datejs"
import {getwpposts, getpostsbycalendar, getpostsbytags, getpostsbysearch} from "@/lib/utils.js"
import taglinks from "@/components/taglinks.vue"
import Paginator from "@/components/paginator.vue"
export default {
components : {taglinks, Paginator},
name : "newsblockUniversalRubricator",
props : [
'category','count','page','mode1','params', 'silent'
    ],

data() {

let showpage=1

//console.log('this.page = '+this.page)

if(this.page)
{
  showpage = this.page
}

return {
newsitems : [],
onpagecount : 6,
showpage,
ccategory : this.category,
pagestotal : 1

 }

},
mounted() {

if(this.silent !=1)
this.getnews()

},
methods : {

async getnews()  {

//console.log("Getting news, page="+this.showpage)

this.pagestotal =1
this.newsitems = await getwpposts(this.ccategory,this.onpagecount,this.showpage)
if(this.newsitems)
if(this.newsitems[0])
  if(this.newsitems[0].totalpages > 1)
    this.pagestotal = this.newsitems[0].totalpages

},

async getcalendar(year,month,day=0)
{


const res = await getpostsbycalendar(year,month,day,this.onpagecount)

if(!res.status)
   return
//console.log(`getcalendar() : `)
//console.log(res.data)
this.newsitems = res.data
this.pagestotal = res.pages

},
async gettags(tagid){

const res = await getpostsbytags(tagid,this.onpagecount)
if(!res.status)
   return
this.newsitems = res.data
this.pagestotal = res.pages

},
async search(key)
{
const res =  await getpostsbysearch(key,this.onpagecount)

if(!res.status)
   return



this.newsitems = res.data
this.pagestotal = res.pages

},
checkpage(params)
{
if(!params.page)
  this.showpage = 1
  return

this.showpage =   params.page

}


},
watch : {

params : function(n,o)
{
//console.log(`newsblock  -- new params : ${n}`)
//console.log(n)
switch (n.mode) {
  case 'category':
  this.ccategory = this.params.category
  this.showpage = 1
  this.getnews()
    break;

case 'calendar1' :
      {  let day = 0
         if(this.$route.params.day)
           day = this.$route.params.day
             this.checkpage(this.$route.params)
    this.getcalendar(this.$route.params.year,this.$route.params.month,day)
  }
   break

   case "calendar":
   {let day = 0
    if(this.$route.params.day)
      day = this.$route.params.day
        console.log(`call getcalendar()`)
        this.checkpage(this.$route.params)
        this.getcalendar(this.$route.params.year,this.$route.params.month,day)
      }
   break


     case 'tags' :
       this.checkpage(this.$route.params)
       this.gettags(n.tagid)
     break

      case 'search' :
         this.checkpage(this.$route.params)
      this.search(n.search)
        break

  default:
     }

},
$route()
{
  //console.log(" newsblock-universal-rubricator.vue : route changed")

  if(this.$route.params)
     if(this.$route.params.page)
       {
         this.showpage = this.$route.params.page
          this.getnews()
       }


}




        }





}

</script>
