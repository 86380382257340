<template>

    <aside id="tag_cloud-3" class="widget widget_tag_cloud">
      <h4 class="widget-title">Метки</h4>
      <div class="tagcloud">

    <router-link v-for="(tag ,index) in tags" :to="{ name: 'rubricatortags', params: {  tagid: tag.id, tagname : tag.name, type : 'tags' }}" :class="`tag-cloud-link tag-link-${tag.id} `" :style="`font-size: ${gettagsize(12)}; ${additionalstyles}`" :aria-label="tag.name" >{{tag.name}}</router-link>

  </div>
    </aside>

</template>

<script>
import {gettagscloud} from "@/lib/utils.js"
export default {
name : 'tagscloud',
computed : {


},
data() {

return {
tags : [],
additionalstyles : 'position:relative; display: inline-block;'

}

},
methods : {

gettags : async function(){
const res = await gettagscloud()
    if(res[0])
    {
      this.tags = res
    }

      },
  gettagsize(size)
          {
        return "12px"
              }
},
mounted() {
this.gettags()

 }

}
</script>
