<template>


    <div id="main" class="site-main">

    <div id="main-content" class="main-content">

    <div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style=""> </h1></div></div></div>
    	<div class="block-content">
    		<div class="container">
    			<div class="panel row panel-sidebar-position-right with-sidebar">

            <newsblockUniversalRubricator :params="rubparams" :silent="silent" />


            <div class="sidebar col-lg-3 col-md-3 col-sm-12" role="complementary"><div class="widget-area">

      <searchbar />

          <calendar :month="calendarmonth" :year="calendaryear"  />

        <rubricator :position="rubrica"/>

  <tagscloud />

  </div>
    </div><!-- .sidebar -->			</div>
    		</div><!-- .container -->
    	</div><!-- .block-content -->
    </div><!-- #main-content -->


  </div>
</template>
<script>
import {rollup} from "@/lib/utils.js"
import rubricator from "@/components/rubricator.vue"
import calendar from "@/components/calendar.vue"
import searchbar from "@/components/searchbar.vue"
import tagscloud from "@/components/tagscloud.vue"
import newsblockUniversalRubricator from "@/components/news/newsblock-universal-rubricator.vue"
import categories  from "@/lib/categories.js"
export default {
name : 'rubricatorpage',

watch : {


$route() {
console.log('route changes')
console.log(this.$route.params)
console.log(`name is : `)
console.log(this.$route.name)

if(this.$route.name == 'rubricatorcalendar1')
  this.setcalendarmode()

  if(this.$route.name == 'rubricatorcalendar2')
    this.setcalendarmode()

  if(this.$route.name == 'rubricatorsearch')
    this.setsearchmode()

    if(this.$route.name == 'rubricatortags')
      this.settagsmode()

      if(this.$route.name == 'rubricatorcategory')
       this.setcategorymode()



}



},
components : {calendar,searchbar,rubricator,tagscloud, newsblockUniversalRubricator},
data(){

console.log(this.$route.params)
console.log(this.$route.name)

let year = new Date().getFullYear()
let month = new Date().getMonth()+1

if( (this.$route.params.year) && (this.$route.params.month) && ( (this.$route.name == 'rubricatorcalendar1') || (this.$route.name == 'rubricatorcalendar2')  ) )
 {
   year = this.$route.params.year
   month = this.$route.params.month


 }


return  {
tagsparams : {},
searchparams : {},
calendarparams : {},
calendaryear : year,
calendarmonth : month,
calendarday : '',
rubparams : {},
rubmode : '',
silent : 1,
rubrica : ''
}

},
methods : {
setcalendarmode(){

console.log("rubricator.vue : calendar mode")
rollup()
this.rubparams = this.$route.params
this.rubparams.mode = 'calendar'

  },
setsearchmode() {
console.log("search mode")
rollup()
this.rubparams = this.$route.params
this.rubparams.mode = 'search'

   },
settagsmode() {
rollup()

  console.log("index : settagsmode()")
  console.log(this.$route.params)
this.rubparams = this.$route.params
this.rubparams.mode = 'tags'

},
setcategorymode() {
console.log("show category")
rollup()
const category =  this.$route.params.category

let cat =''

if(categories[category])
{
    cat = categories[category]
    this.$router.push({name : cat, replace: true})
    return
  }

let page = 1
if(this.$route.params.page)
   page = this.$route.params.page

   this.rubparams = {mode: 'category', category : this.$route.params.category, page}
  this.rubrica = 'norubric'


}


},
mounted()
{
  rollup()
console.log(`rubricator mounted`)
console.log(this.$route.params)

switch (this.$route.name) {
  case 'rubricatorcalendar1':
     this.setcalendarmode()
        break
case "rubricatorcalendar2" :
     this.setcalendarmode()
      break

case "rubricatortags" :
    this.settagsmode()
    break

  case "rubricatorsearch" :
     this.setsearchmode()
       break

  case "rubricatorcategory" :
     this.setcategorymode()
      break

  default:

}


}



}
</script>
